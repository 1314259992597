import React from 'react';
import styles from './Avatar.module.scss';
import { MemberIcon } from 'theme/icons';
import { AvatarProps } from './Avatar.interface';

export function Avatar({ profilePhotoUrl }: AvatarProps): JSX.Element {
  return (
    <div>
      {profilePhotoUrl ? (
        <img width='120' src={profilePhotoUrl} className={styles.accountSidebarAvatarImg} />
      ) : (
        <MemberIcon className={styles.accountSidebarAvatarIcon} />
      )}
    </div>
  );
}
