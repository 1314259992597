import React, { PropsWithChildren } from 'react';
import styles from './TextBlock.module.scss';
import { TextBlockProps } from './TextBlock.interface';
import cn from 'classnames';

const TextBlock = ({
  size,
  color,
  spacing,
  align,
  children,
  ...props
}: PropsWithChildren<TextBlockProps>): JSX.Element => {
  return (
    <div
      {...props}
      className={cn(props.className, styles.TextBlock, {
        [styles.TextBlockMedium]: size === 'medium',
        [styles.TextBlockSmall]: size === 'small',
        [styles.TextBlockMediumSmall]: size === 'medium-small',
        [styles.TextBlock14lg12sm]: size === '14lg-12sm',
        [styles.TextBlockGray]: color === 'gray',
        [styles.TextBlockMediumDarkGray]: color === 'medium-dark-gray',
        [styles.TextBlockSlate]: color === 'slate',
        [styles.TextBlockBottom]: spacing === 'bottom',
        [styles.TextBlockAlignCenter]: align === 'center',
        [styles.TextBlockAlignRight]: align === 'right',
      })}
    >
      {children}
    </div>
  );
};

export default TextBlock;
