import Head from 'next/head';
import React, { useContext, useEffect } from 'react';
import { LayoutType } from 'types/GlobalTypes';
import { UseStyles } from 'hooks';
import { Sidebar } from '@components/Account/Sidebar';
import { UnauthenticatedLayout } from 'components/HomeLayout';
import AppshellContext from '@inc/AppshellContext';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { themeDark } from '../../../theme';
import ConfirmExitDialog from 'components/Global/components/ConfirmExitDialog';
import Message from 'components/Global/components/Message';
import EventManager from 'services/EventManager';
import { pageTitle } from 'utils';
import { PageTitle } from '@ui/atoms/PageTitle/PageTitle';
import { HeaderDefault } from '@components/Header/HeaderDefault';

export function AccountLayout({ children, title, headTitle, variant = 'default' }: LayoutType): JSX.Element {
  const classes = UseStyles();
  const userContext = useContext(AppshellContext);
  const data = userContext?.user?.data;
  useEffect(() => {
    if (title || headTitle) {
      EventManager.dispatch('page.viewed', headTitle ?? title);
    }
  }, []);

  // Add body class for account pages.
  useEffect(() => {
    document.body.classList.add('page-account');
    return () => {
      document.body.classList.remove('page-account');
    };
  }, []);
  return (
    <>
      <Head>
        <title>{pageTitle(headTitle ?? title ?? 'User Account')}</title>
      </Head>
      {data ? (
        <ThemeProvider theme={themeDark}>
          <HeaderDefault showMenu={variant === 'box' ? false : true} />
          {variant === 'default' ? (
            <Typography component='div' variant='body2' className={classes.accountWrapper}>
              <div className={classes.accountContentWrapper}>
                <div className={classes.accountContentInner}>
                  <Sidebar className={classes.accountSidebar} />
                  <div className={classes.accountContent}>
                    <PageTitle hide='mobile'>{title}</PageTitle>
                    <ConfirmExitDialog />
                    <Message />
                    {children}
                  </div>
                </div>
              </div>
            </Typography>
          ) : (
            <div className={classes.boxPage}>
              {title && <h1 className={classes.boxPageTitle}>{title}</h1>}
              {children}
            </div>
          )}
        </ThemeProvider>
      ) : (
        <UnauthenticatedLayout />
      )}
    </>
  );
}
