import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog } from 'containers/Dialog/ConfirmDialog';
import { useStoreon } from 'storeon/react';
import { useRouter } from 'next/router';

export default function ConfirmExitDialog(): JSX.Element {
  const [exitPath, setExitPath] = useState('/');
  const { dispatch, context } = useStoreon('context');
  const modified = useRef(context.formModified);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (path: string) => {
      if (modified.current) {
        // if (window.confirm('There are unsaved data. Are you sure you want to leave the page?')) {
        //   dispatch('context/set', ['formModified', false]);
        //   return true;
        // }
        // throw "Abort route change by user's confirmation.";
        setExitPath(path);
        dispatch('dialog/open', { id: 'confirmExit' });
        throw 'Abort route change by user confirmation.';
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
  }, []);
  useEffect(() => {
    modified.current = context.formModified;
  }, [context.formModified]);
  useEffect(() => {
    const beforeUnloadListener = (e: any) => {
      if (modified.current) {
        e.returnValue = true;
      }
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => window.removeEventListener('beforeunload', beforeUnloadListener);
  }, []);
  return (
    <>
      <ConfirmDialog
        name='confirmExit'
        title="You're leaving the page..."
        description='There are unsaved data. Are you sure you want to leave the page?'
        handle={() => {
          dispatch('context/set', ['formModified', false]);
          router.push(exitPath);
        }}
      />
    </>
  );
}
